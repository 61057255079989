<template>
    <step-settings-wrapper title="Send Settings" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Airdrop
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="emailEnabled" :value="preset.sendSettings.activityItemEnabled" v-model="preset.sendSettings.activityItemEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Email
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="emailEnabled" :value="preset.sendSettings.emailEnabled" v-model="preset.sendSettings.emailEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    SMS
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="smsEnabled" :value="preset.sendSettings.smsEnabled" v-model="preset.sendSettings.smsEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Custom Sender Email Address
                </div>
                <div class="relative font-work-sans mt-10">
                    <label for="custom_sender_email" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon icon="envelope" class="text-lg pr-1 align-top"></font-awesome-icon> Set a custom sender email address...</label>
                    <input type="text" name="sendGridFromEmail" id="custom_sender_email" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.sendSettings.client.sendGridFromEmail" />
                </div>
            </div>

            <div class="pb-2 pt-5">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Subject
                </div>
                <div class="relative font-work-sans mt-10">
                    <label for="message_title" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon icon="envelope" class="text-lg pr-1 align-top"></font-awesome-icon> Set an email subject line...</label>
                    <input type="text" name="messageTitle" id="message_title" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.sendSettings.messageTitle" />
                </div>
            </div>


        </div>

        <div class="pb-2 pt-5" v-if="preset.sendSettings.emailEnabled">
            <ul class="flex border-b border-gray-custom-2">
                <li class="mr-1" :class="{'-mb-px': messageBodyView === 'code'}" @click.prevent="changeView('code')">
                    <a class="bg-black inline-block border-gray-custom-2 rounded-t py-2 px-4 text-2xl uppercase font-bold tracking-wider" :class="{'border-l border-t border-r rounded-t': messageBodyView === 'code'}" href="#">Plain Text / Code</a>
                </li>
                <li class="mr-1" :class="{'-mb-px': messageBodyView === 'preview'}" @click.prevent="changeView('preview')">
                    <a class="bg-black inline-block border-gray-custom-2 rounded-t py-2 px-4 text-2xl uppercase font-bold tracking-wider" :class="{'border-l border-t border-r rounded-t': messageBodyView === 'preview'}" href="#">Preview</a>
                </li>
            </ul>
            <div class="font-work-sans">
                <textarea name="messageBody" id="message_body" class="bg-transparent w-full p-3 outline-none border border-gray-custom-2 font-work-sans-medium tracking-wider text-xs h-64 border-t-0" v-model="preset.sendSettings.messageBody" placeholder="Message" v-if="messageBodyView === 'code'"></textarea>
            </div>
            <div :class="{'p-3 border border-gray-custom-2 border-t-0': messageBodyView === 'preview'}">
            <iframe id="previewFrame" ref="previewFrame" class="w-full bg-white" height="0px" @load="iframeLoad">
            </iframe>
            </div>
        </div>

    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue'
    import { ToggleButton } from 'vue-js-toggle-button'

    export default {
        name: 'SendSettings',
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        data: function() {
            return {
                beforeEditing: {},
                messageBodyView: 'code'
            }
        },
        components: {
            StepSettingsWrapper,
            ToggleButton
        },
        destroyed: function() {
            this.checkMessageTitleBeforeDestroy();
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.sendSettings);
            this.checkMessageTitleOnMounted();
            if( this.preset.sendSettings.messageTitle !== '' ) {
                document.getElementById('message_title').previousElementSibling.style.top = '-30px';
            }
            if( this.preset.sendSettings.client.sendGridFromEmail !== '' ) {
                document.getElementById('custom_sender_email').previousElementSibling.style.top = '-30px';
            }
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-30px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                }
            },
            onClose: function(toRoute) {
                //this.preset.sendSettings = Object.assign({}, this.beforeEditing);
                this.checkMessageTitleBeforeDestroy();
                let filesSelected = 0;
                this.$emit('close', 'sendSettings', this.beforeEditing, filesSelected, toRoute);
                this.checkMessageTitleOnMounted();
            },
            onSave: function() {
                this.$emit('save', 'sendSettings');
            },
            changeView: function(view) {
                this.messageBodyView = view;
                if( view === 'preview' ) {
                    //if contains html
                    if( /<\/?[a-z][\s\S]*>/i.test(this.preset.sendSettings.messageBody) ) {
                        this.$refs.previewFrame.srcdoc = this.preset.sendSettings.messageBody;
                    } else {
                        //if looks like not an html content i.e text
                        this.$refs.previewFrame.srcdoc = this.preset.sendSettings.messageBody.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    }
                } else {
                    this.$refs.previewFrame.height = '0px';
                }
            },
            iframeLoad: function() {

                if( this.messageBodyView === 'preview' ) {

                    let iframeHeight = this.$refs.previewFrame.contentWindow.document.body.scrollHeight;
                    if( iframeHeight < 256 )  {
                        iframeHeight = 256;
                    }

                    this.$refs.previewFrame.height = iframeHeight + 'px';
                }

            },
            checkMessageTitleBeforeDestroy: function() {
                if( this.preset.sendSettings.messageTitle === '' ) {
                    this.preset.sendSettings.messageTitle = 'Your Boothclub photo';
                }
            },
            checkMessageTitleOnMounted: function() {
                if( this.preset.sendSettings.messageTitle === 'Your Boothclub photo' ) {
                    this.preset.sendSettings.messageTitle = '';
                }
            }
        }
    }
</script>

<style scoped>
    label {
        transition: top 0.2s ease-out;
    }
</style>